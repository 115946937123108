import Main from '@/components/main/main'
// import parentView from '@/components/parent-view'

/**
 * iview-admin中meta除了原生参数外可配置的参数:
 * meta: {
 *  title: { String|Number|Function }
 *         显示在侧边栏、面包屑和标签栏的文字
 *         使用'{{ 多语言字段 }}'形式结合多语言使用，例子看多语言的路由配置;
 *         可以传入一个回调函数，参数是当前路由对象，例子看动态路由和带参路由
 *  hideInBread: (false) 设为true后此级路由将不会出现在面包屑中，示例看QQ群路由配置
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  beforeCloseName: (-) 设置该字段，则在关闭当前tab页时会去'@/router/before-close.js'里寻找该字段名对应的方法，作为关闭前的钩子函数
 *  keepAlive: 是否需要缓存（true)需要缓存 不需要缓存可以不设置
 * }
 */
// window.routers = {}
const router = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - 登录',
      hideInMenu: true
    },
    component: resolve => (require(['@/views/login/login.vue'], resolve))
  },
  {
    path: '/login-new-tenant',
    name: 'login-new-tenant',
    meta: {
      title: '登录中...',
      hideInMenu: true
    },
    component: () => import('@/views/login-new-tenant/index.vue')
  },
  {
    path: '/loading-login-sso',
    name: 'loading-login-sso',
    meta: {
      title: '登录中...',
      hideInMenu: true
    },
    component: () => import('@/views/loading-login-sso/loading-login-sso.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Main,
    meta: {
      title: '首页',
      icon: 'icon-shouye'
    },
    redirect: '/home-page',
    children: [
      {
        path: '/home-page',
        name: 'home-page',
        meta: {
          title: '首页',
          code: '001'
        },
        component: resolve => (require(['@/views/home/index.vue'], resolve))
      },
      {
        path: '/internet-applications',
        name: 'internet-applications',
        meta: {
          title: '应用软件',
          code: '002',
          goodsClass: '1300000000'
        },
        component: resolve => (require(['@/views/internet-applications/index.vue'], resolve)),
        redirect: '/industry-solution',
        children: [
          {
            path: '/industry-solution',
            name: 'industry-solution',
            meta: {
              title: '行业方案',
              code: '00201',
              goodsClass: '1303000000',
              icon: 'industry-solution'
            },
            component: resolve => (require(['@/views/internet-applications/industry-solution/index.vue'], resolve))
          },
          {
            path: '/product-system',
            name: 'product-system',
            meta: {
              title: '产品体系',
              code: '00202',
              goodsClass: '2',
              icon: 'product-system'
            },
            component: resolve => (require(['@/views/internet-applications/product-system/index.vue'], resolve))
          },
          {
            path: '/product-application',
            name: 'product-application',
            meta: {
              title: '产品应用',
              code: '00203',
              goodsClass: '1',
              icon: 'product-application'
            },
            component: resolve => (require(['@/views/internet-applications/product-application/index.vue'], resolve))
          }
        ]
      },
      {
        path: '/hardware-equipment',
        name: 'hardware-equipment',
        meta: {
          title: '硬件设备',
          code: '003'
        },
        component: resolve => (require(['@/views/hardware-equipment/index.vue'], resolve))
      },
      {
        path: '/resource-bundle',
        name: 'resource-bundle',
        meta: {
          title: '资源包',
          code: '004'
        },
        component: resolve => (require(['@/views/resource-bundle/index.vue'], resolve))
      },
      {
        path: '/video-page',
        name: 'video-page',
        meta: {
          title: '视频',
          code: '005'
        },
        component: resolve => (require(['@/views/video-page/index.vue'], resolve))
      },
      {
        path: '/development-platform',
        name: 'development-platform',
        meta: {
          title: '开发平台',
          code: '007'
        }
      },
      {
        path: '/about-jyq',
        name: 'about-jyq',
        meta: {
          title: '关于聚优企',
          code: '006'
        },
        component: resolve => (require(['@/views/about-jyq/index.vue'], resolve))
      }
    ]
  },
  {
    path: '/details',
    name: 'details',
    component: Main,
    meta: {
      title: '详情',
      icon: 'icon-shouye'
    },
    children: [
      {
        path: '/industry-details',
        name: 'industry-details',
        meta: {
          title: '行业方案-详情',
          code: '0020101'
        },
        component: resolve => (require(['@/views/internet-applications/industry-solution/details.vue'], resolve))
      }
    ]
  },
  {
    path: '/shopping-cart',
    name: 'shopping-cart',
    component: Main,
    meta: {
      title: '购物车',
      code: '007',
      icon: 'icon-shouye'
    },
    children: [
      {
        path: '/cart',
        name: 'cart',
        meta: {
          title: '购物车',
          code: '00701'
        },
        component: resolve => (require(['@/views/shopping-cart/cart.vue'], resolve))
      },
      {
        path: '/payment-center',
        name: 'payment-center',
        meta: {
          title: '支付中心',
          code: '00702'
        },
        component: resolve => (require(['@/views/shopping-cart/payment-center.vue'], resolve))
      },
      {
        path: '/payment-pullic-loading',
        name: 'payment-pullic-loading',
        meta: {
          title: '支付方式选择',
          hideInMenu: true
        },
        component: () => import('@/views/shopping-cart/components/payment-pullic-loading.vue')
      },
    ]
  }
]
let newRoutes = [...router]
window.allRouters = newRoutes

export default newRoutes
