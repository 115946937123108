// 周报管理
import axios from '@/libs/api.request'

// 账号名
export const authLogin = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/loginByName`,
    method: 'post'
  })
}
// 退出登录
export const logout = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/logout`,
    method: 'post'
  })
}
// 登录验证码发送
export const loginSendAuthCode = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/sendAuthCode`,
    method: 'post'
  })
}
// 修改密码验证码发送
export const resetPasswordSendAuthCode = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/resetPasswordSendAuthCode`,
    method: 'post'
  })
}
// 手机号登录
export const mobileLogin = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/loginByMobile`,
    method: 'post'
  })
}

// 获取登录用户
export const getCurrentUser = (data) => {
  return axios.request({
    data: data,
    url: '/api/auth/getCurLoginUserInfo',
    method: 'post'
  })
}

// 获取租户信息表
export const querySingleTenantInfoByDomainByRequset = (data) => {
  return axios.request({
    data: data,
    url: `/api/tenant/querySingleTenantInfoByDomainByRequset`,
    method: 'post'
  })
}
// 重定向后获取用户信息
export const codeLogin = (data) => {
  return axios.request({
    url: `/api/auth/codeLogin${data}`,
    method: 'get'
  })
}

// 根据用户id获取菜单-当前用户
export const getMenuListByCurrentUser = (data) => {
  return axios.request({
    url: `/api/auth/getCurLoginUserInfo`,
    method: 'post'
  })
}
// 根据当前选择租户登录
export const loginByUserIdAndTenantId = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/loginByUserIdAndTenantId`,
    method: 'post'
  })
}
// 企业注册

// 注册验证码发送
export const registerSendAuthCode = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/sendregisterTenantAuthCode`,
    method: 'post'
  })
}

// 注册企业
export const registerTenant = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/registerTenant`,
    method: 'post'
  })
}

// 获取当前企业信息
export const getCurTenantInfo = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/getCurTenantInfo`,
    method: 'post'
  })
}

// 查询企业信息
export const queryEnterpriseBusiness = (data) => {
  return axios.request({
    data: data,
    url: `/api/api/queryEnterpriseBusiness`,
    method: 'post'
  })
}

// 校验验证码并获取注册信息
export const getRegisterInfo = (data) => {
  return axios.request({
    data: data,
    url: `/api/register/getRegisterInfo`,
    method: 'post'
  })
}
// 提交注册信息(简)
export const commitSimpleRegisterInfo = (data) => {
  return axios.request({
    data: data,
    url: `/api/register/commitSimpleRegisterInfo`,
    method: 'post'
  })
}
// 获取租户系统配置
export const getTenantSystemConfig = (data) => {
  return axios.request({
    data: data,
    url: `/api/tenant/getTenantSystemConfig`,
    method: 'post'
  })
}
// 获取风险等级
export const getLoginRiskControlLevel = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/getLoginRiskControlLevel`,
    method: 'post',
    headers: {
      noEncryption: true
    }
  })
}
// 获取验证图片  以及token
export const captchaGet = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/createCaptcha`,
    method: 'post',
    headers: {
      noEncryption: true
    }
  })
}

// 滑动或者点选验证
export const captchaCheck = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/checkCaptcha`,
    method: 'post',
    headers: {
      noEncryption: true
    }
  })
}
// 账号登录短信验证
export const sendAuthCodeByLoginName = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/sendAuthCode`,
    method: 'post',
    headers: {
      noEncryption: true
    }
  })
}

// 获取当前用户的信息
export const getCurrentUserInfo = (data) => {
  return axios.request({
    data: data,
    url: `/api/jyq-base/admin/user/getCurrentUserInfo`,
    method: 'post',
    headers: {
      noEncryption: true
    }
  })
}

// 获取其它信息
export const getOtherInfo = (data) => {
  return axios.request({
    data: data,
    url: `/api/auth/getOtherInfo`,
    method: 'post'
  })
}