<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>

import { removeWatermark, setWaterMark } from '@/libs/watermark'
import mixCommon from '@/common/mixCommon.js'
export default {
  name: 'App',
  data () {
    return {
      //   version: ''
    }
  },
  mounted () {
    console.log(process.env.NODE_ENV)
    console.log(process.env.VUE_APP_API_PREFIX)
    // setTimeout(() => {
    //   setWaterMark('', '')
    // }, 1000)
  },
  destroyed () {
    // removeWatermark()
  },
  created () {
    if (sessionStorage.getItem('token')) {
      this.$store.commit('set_token', sessionStorage.getItem('token'))
    }
    if (sessionStorage.getItem('showTime') === '0') {
      mixCommon.lockBg()
    }
  },
  methods: {

  }
}
</script>
<style lang="less">
.size{
  width: 100%;
  height: 100%;
}
body {
  min-width: 1200px;
}
html,body{
  .size;
  // overflow: hidden;
}
#app {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 0;
  margin: 0;
  // width: ~'calc(100% - 0.8rem)';
  width: 100%;
  overflow-x:hidden;
}
#mask_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255,1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    margin-top: 310px;
    width: 387px;
    height: 233px;
  }
  .prompt1{
    margin-top: 32px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #464646;
    line-height: 20px;
  }
  .prompt2{
    margin-top: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #99A0AF;
    line-height: 16px;
  }
}
</style>
