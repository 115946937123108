import router from '@/router/index'
import moment from 'moment'
import { Message, Spin, Modal } from 'view-design'
import MenuMethods from '@/libs/menu.js'
import config from './config'
import store from '@/store/index'

import { getEnumListByCls, getCurrentUser } from '@/api/data'
import { previewUrl, ossPreviewUrl } from '@/api/document-sack'
import { getOtherInfo } from '@/api/logins'
import { getCurEffectiveCartAndOrder } from '@/api/internet-applications'
let Base64 = require('js-base64').Base64

let findkeyIndex = (arr, key, val) => { // 查找数组下标值
  if (arr && arr.length > 0) {
    if (key) {
      return arr.findIndex((mitem) => mitem[key] == val)
    } else {
      return arr.findIndex((mitem) => mitem == val)
    }
  } else {
    return -1
  }
}

let changeTImeStamp = (val) => { // 日期转时间戳
  return moment(val).valueOf()
}
let changeTimeDate = (val, type = 'YYYY-MM-DD') => { // 时间戳转日期
  if (!val) {
    return val
  }
  val = String(val)
  if (val && val.indexOf('-') > -1 && type == 'YYYY.MM.DD') {
    let arr = val.split('-')
    val = arr.join('.')
    return val
  } else {
    let values = val ? String(val) : ''
    if (values.indexOf('-') == -1 && values) {
      return moment(Number(values)).format(type)
    } else {
      return val || ''
    }
  }
}

let changePrice = (str = 0, type, num = 2, precision = 12) => { // 转换金额格式  或百分比格式
  let val = Trim(str) || ''
  val = Number(val)
  if (type == '%' && val) {
    val = (val * 100)
    val = Number(val).toFixed(num)
    val = `${val}%`
  } else if (type == 'price' && val) {
    val = comdify(delcommafy(parseFloat(val.toPrecision(precision))))
  } else if (val) {
    val = Number(val).toFixed(num).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') || 0
  }
  return val
}
// 表单验证
// ruleValidate  一级菜单
/**
 *
 * @param {*} mehtodsName 方法名
 * @param {*} params 参数
 * @param {*} childrenparams 二级参数  回调 (类型数据 ['contacts'])
 * @param {*} fn 回调函数
 *
 * @typedef {object} fn 配置
 */
let validComForm = (mehtodsName, params, childrenparams, fn, name) => {
  mehtodsName(params).then(res => {
    const { data, headers } = res
    let validObj = {}
    let validChildrenObj = {}
    let arrKeyRight = {}
    childrenparams.map((item, index) => {
      arrKeyRight[item] = []
    })
    if (data.code == 14 && data['data']) {
      let reqFieldErrorList = res.data.data.reqFieldErrorList || []
      let reqFormatErrorList = res.data.data.reqFormatErrorList || []
      let arrReq = reqFieldErrorList.concat(reqFormatErrorList)
      let ruleValidate = arrReq
      ruleValidate.map((item, index) => {
        if (item.fields.length == 1) {
          validObj[item.fieldStr] = [ { required: true, message: item.message } ]
        }
        if (item.fields.length == 3 && childrenparams.length > 0) { // 含有子集
          childrenparams.map((keyName, index2) => {
            if (keyName == item.fields[0].field) { // 若传入参数名等于 后端返回的参数名
              let childrenName = item.fields[2].field
              !arrKeyRight[keyName][item.fields[1].index] && (arrKeyRight[keyName][item.fields[1].index] = {})
              arrKeyRight[keyName][item.fields[1].index][childrenName] = [{ required: true, message: item.message, offRequire: true }]
            }
          })
        }
      })
      fn(data, { validObj: validObj, validChildrenObj: validChildrenObj, arrKeyRight: arrKeyRight })
    } else {
      fn(data)
    }
  }).catch(async e => {
    console.log('验证失败', e)
  })
}

let Trim = (str) => { // 去除前后空格
  str = String(str)
  return str ? str.replace(/(^\s*)|(\s*$)/g, '') : str || ''
}
// 金额添加千分位
const comdify = function (n) {
  if (!n) return n
  let str = n.split('.')
  let re = /\d{1,3}(?=(\d{3})+$)/g
  let n1 = str[0].replace(re, '$&,')
  // return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}`
}
// 去除千分位中的‘，'
const delcommafy = function (num) {
  if (!num) return num
  num = num.toString()
  num = num.replace(/,/gi, '')
  return num
}
/**
 *
 * @param {*} val
 * @param {*} type
 * @returns
 * 无函数   {code: 1,name: 1}
 * 有函数   {code: 1,name: 1,fn=(()={})}
 */
let deepCopy = (val, type) => { // 深拷贝
  if (!type) { // 无函数
    return JSON.parse(JSON.stringify(val))
  } else { // 有函数
    return Object.assign(type || {}, val)
  }
}

const lockBg = () => {
  store.commit('changeTimeIng', true)
  const mask_bg = document.createElement('div')
  mask_bg.id = 'mask_bg'
  mask_bg.innerHTML = `<img src="` + require('@/assets/images/lock_bg.png') + `" alt="">
  <p class="prompt1">由于您长时间未操作，出于安全考虑，平台将保护您的数据安全</p>
  <p class="prompt2">点击任意处返回</p>`
  document.body.appendChild(mask_bg)
  mask_bg.addEventListener('click', () => {
    getCurrentUser({ loadingOff: true }).then(res => {
      const { code } = res.data
      if (code === 0) {
        store.commit('changeShowTime', 3600)
        store.commit('changeTimeIng', false)
        document.body.removeChild(mask_bg)
      }
    })
  })
}
const lock = () => {
  const lockBox = document.querySelector('.lockBox')
  if (lockBox) {
    return
  }
  let val = ''
  Modal.confirm({
    render: (h) => {
      return h('div', [
        h(
          'p',
          {
            style: {
              color: '#606266',
              fontSize: '18px',
              marginLeft: '10px',
              marginBottom: '10px'
            }
          },
          '请输入密码重新登录'
        ),
        h('Input', {
          props: {
            value: val,
            placeholder: '请输入密码'
          },
          class: 'lockBox',
          on: {
            input: (value1) => {
              val = value1
            }
          }
        })
      ])
    },
    onOk: () => {
      if (!val) {
        const msg = document.querySelector('.ivu-message')
        if (msg) {
          document.querySelector('.ivu-message').innerHTML = ''
        }
        messageOnce.error('密码不能为空')
        setTimeout(() => {
          lock()
        }, 1000)
      } else {
        checkUserPwd({ password: val }).then(res1 => {
          // val = ''
          const { code } = res1.data
          if (code === 0) {
            store.commit('changeShowTime', 3600)
            store.commit('changeTimeIng', false)
            const mask_bg = document.querySelector('#mask_bg')
            document.body.removeChild(mask_bg)
          } else {
            setTimeout(() => {
              lock()
            }, 1000)
          }
        })
      }
    },
    onCancel: async () => {
      store.commit('del_all')
      await logout().catch(console.log)
      setTimeout(() => {
        router.replace({ path: '/' })
      }, 800)
      const mask_bg = document.querySelector('#mask_bg')
      store.commit('changeShowTime', 3600)
      store.commit('changeTimeIng', false)
      document.body.removeChild(mask_bg)
      Modal.remove()
    },
    cancelText: '重新登录',
    okText: '登录'
  })
}

const getHideMenu = () => {
  let arr = []
  let newarr = treeChange(window.allRouters, arr)
  return newarr
}
const treeChange = (arrList, arr) => {
  arrList.map((item) => {
    item.children && treeChange(item.children, arr)
    if (item.meta.hideInMenu) {
      arr.push(item.name)
    }
    if (item.children && item.children.length > 0 && item.meta.hideInMenu) {
      let childrenArr = treeChange(item.children, arr)
      arr = [arr, ...childrenArr]
    }
  })
  return arr
}

const getMenuListByCurrentUser = async (data, menuList, val) => { // 根据用户id获取菜单-当前用户
  if (menuList && menuList.length > 0 && (val && val != 'new')) {
  } else if (sessionStorage.getItem('leftMenu')) {
    if (sessionStorage.getItem('leftMenu')) {
      menuList = JSON.parse(sessionStorage.getItem('leftMenu')) ? JSON.parse(sessionStorage.getItem('leftMenu')) : await MenuMethods.getMenu()
    } else {
      menuList = await MenuMethods.getMenu()
    }
  } else {
    menuList = await MenuMethods.getMenu()
  }
  console.log('触发了')
  let loginFlag = false
  console.log(menuList)
  for (let menu of menuList) {
    // let offIndex = menu.frontPath ? findkeyIndex(config.routerList, '', menu.frontPath):findkeyIndex(config.routerList, '', menu.name)
    let offIndex = findkeyIndex(config.routerList, '', menu.name)
    if (offIndex <= -1) {
      Message.success('登录成功')
      loginFlag = true
      router.push({ name: 'home' })
      Spin.hide()
      break
    }
    //
  }
  if (!loginFlag) {
    Message.error('登录失败,该用户暂无菜单权限')
    router.replace({ path: '/login' })
    sessionStorage.clear()
    Spin.hide()
  } else {
    if (!menuList || menuList.length == 0) {
      Message.error('登录失败,该用户暂无菜单权限')
      router.replace({ path: '/login' })
      sessionStorage.clear()
      Spin.hide()
      return
    }
  }
  if (val == 'new') {
    // setTimeout(() => {
    window.location.reload()
    // }, 1000);
  }
}

const findTargetRouter = (menuList = []) => {
  let router = menuList[0]
  while ((router.children || []).length > 0) {
    router = router.children[0]
  }
  return router
}


/**
 *从下往上找
 * @param {Array} treeData
 * @param {*} id
 * @returns
 */

 const findParents = (treeData, val) => {
  if (treeData.length == 0) return
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].meta.code == val) {
      return []
    } else {
      if (treeData[i].children) {
        let res = findParents(treeData[i].children, val)
        if (res !== undefined) {
          return res.concat(treeData[i]).reverse()
        }
      }
    }
  }
}
/**
 *
 * @param {*} paraName '获取可以'
 * @param {*} off 是否开启解密
 * @returns
 */
const GetUrlParam = (paraName, urlV) => {
  // let url = document.location.toString();
  let url = urlV || window.location.href
  let arrObj = url.split('?')
  if (arrObj.length > 1) {
    let arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')

      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}
const analysisUrl = (paraName, url, off = false) => {
  let resObj = {}
  let jxObj = GetUrlParam(paraName, url)
  if (jxObj) {
    if (off) {
      let obj = JSON.parse(Base64.decode(jxObj))
      resObj = obj
    } else {
      resObj = jxObj
    }
  }

  return resObj
}

/**
 *
 * @param
 * @returns
 */
// 获取url的参数
const getUrlSearch = () => {
  var url = window.location.search
  var urlObj = {}
  if (url.indexOf('?') > -1 && url.indexOf('&') < 0) {
    var vname = url.split('?')[1].split('=')[0]
    var val = url.split('=')[1]
    urlObj[vname] = val
  } else if (url.indexOf('?') > -1) {
    var vsearch = url.split('?')[1].split('&')
    vsearch.forEach((item) => {
      var vname = item.split('=')[0]
      urlObj[vname] = item.split('=')[1]
    })
  }
  return urlObj
}

/**
 *
 * @param {*} dataKey 枚举名称
 * @param {*} code   code 值
 */
const getTypeData = async (dataKey, code) => {
  return new Promise((resolve, reject) => {
    let data = { enumClassName: dataKey }
    let list = []
    let kuData = sessionStorage.getItem('enumerationData') ? JSON.parse(sessionStorage.getItem('enumerationData')) : {}
    let showName = ''
    if (kuData.hasOwnProperty(`${data.enumClassName}`)) {
      list = kuData[`${data.enumClassName}`]
      if (JSON.stringify(code)) {
        if (list && list.length > 0) {
          let offIndex1 = findkeyIndex(list, 'code', code)
          showName = offIndex1 > -1 ? list[offIndex1].name : ''
          resolve(showName)
        } else {
          resolve(showName)
        }
      } else {
        resolve(list)
      }
    } else {
      getEnumListByCls({ enumClassName: data.enumClassName }).then((res) => {
        const { data } = res
        // 处理数组
        if (data.code == 0) {
          list = data.data
          if (JSON.stringify(code)) {
            if (list && list.length > 0) {
              let offIndex1 = findkeyIndex(list, 'code', code)
              showName = offIndex1 > -1 ? list[offIndex1].name : ''
              resolve(showName)
            } else {
              resolve(showName)
            }
          } else {
            resolve(list)
          }
        }
      })
    }
  })
}

let loginFlaOrNot = (val = false) =>{
  let show = false
  if (sessionStorage.getItem('token')) {
    show = true
  } else {
    if (val) {
      Message.error('未登录,请前往登录')
    }
    // router.push({ name: 'login' })
    show = false
  }
  return show
}

let totalAmount = (arr = []) => {
  let amount = 0
  if (arr.length > 0) {
    arr.map(item => {
      amount += Number(item)
    })
  }
  return amount
}

let catrAmount = (name, arr) => {
  let arrC = arr ? arr : ['home-page', 'video-page', 'about-jyq']
    if (arrC.indexOf(name) > -1 && loginFlaOrNot()) {
      getCurEffectiveCartAndOrder({}).then(res => {
        let { data, code, msg } = res.data
        if (code != 0) return
        store.commit('set_cartCount', data.goodsCount || 0)
      })
    }
}

let ssoLoginPath = (key, path = 'loading-login-sso', objGw = '') => {
  getOtherInfo({ key: key }).then(res => {
    let { data, code, msg } = res.data
    let url = `${data}${path}${objGw}`
    console.log(url)
    window.open(url, '_blank');
  })
}

let openFile = (item) => { // 查看文件
  if (item.id) {
    previewUrl({ docFileId: item.id }).then(res => {
      const { code, data, msg } = res.data
      if (code === 0) {
        // let url = encodeURIComponent(Base64.encode(decodeURIComponent(res.data.data))) // 要预览文件的访问地址
        let url = encodeURIComponent(Base64.encodeURL(res.data.data)) // 要预览文件的访问地址
        window.open(`/preview/onlinePreview?url=${url}&fullfilename=${item.name || ''}`)
      } else {
        Message.error(data.msg)
      }
    })
  } else {
    if (item.preView) {
      console.log(item.fileUrl)
      let url = encodeURIComponent(Base64.encodeURL(item.fileUrl)) // 要预览文件的访问地址
      window.open(`/preview/onlinePreview?url=${url}&fullfilename=${item.name || ''}`)
      return
    }
    ossPreviewUrl({ path: item.fileUrl }).then(res => {
      const { code, data, msg } = res.data
      if (code === 0) {
        let url = encodeURIComponent(Base64.encodeURL(res.data.data)) // 要预览文件的访问地址
        //   window.open('http://127.0.0.1:8012/priview?url=' + encodeURIComponent(Base64.encode(url)))
        window.open(`/preview/onlinePreview?url=${url}&fullfilename=${item.name || ''}`)
      } else {
        Message.error(data.msg)
      }
    })
  }
}

// 导出文件
let downloadFile = (mehtodsName, params = {}, filename) => {
  mehtodsName(params, { responseType: 'blob' }).then(res => {
    const { data, headers } = res
    if (typeof data === 'object') {
      if (!!data.type && !!data.size) {
        // 返回的是数据流 -- 继续往下走
        Message.success('OK')
      } else {
        // 需要处理异常 -- 需要抛出异常信息
        if (data.code == 0) {
          // data.msg 异常信息
          Message.error(data.msg)
        } else {
          // 没有数据流
          Message.error('下载失败')
        }
        return
      }
    } else {
      Message.error('服务端错误')
      // 需要处理异常 -- 不是文件流，没有办法下载
      return
    }
    const link = document.createElement('a')
    // let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    let blob = new Blob([res.data])
    // let blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    let name = headers['content-disposition']
    let aa = name.split(';')
    let bb = aa[1].split('=')

    if (bb[1].startsWith('{')) {
      let cc = decodeURIComponent(JSON.parse(bb[1]))
      if (cc.indexOf('"') > -1) {
        cc = cc.replace(/"/g, '').trim()
      }
      // let cc = decodeURIComponent(bb[1])
      link.download = makeFileName(cc, filename) // 下载的文件名
    } else {
      let cc = decodeURIComponent(bb[1])
      if (cc.indexOf('"') > -1) {
        cc = cc.replace(/"/g, '').trim()
      }
      link.download = makeFileName(cc, filename) // 下载的文件名
    }

    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href) // 释放URL 对象
    document.body.removeChild(link)
    return { filename }
  }).catch(async e => {
    console.log(e)
    if (e && e.response && e.response.data) {
      let data = e.response.data
      if (e.request && data && e.request.responseType === 'blob') {
        let resData = JSON.parse(await data.text())
        Message.error(resData.msg)
        return
      }
    }
    console.log(e)
    Message.error('下载失败')
  })
}
let makeFileName = (ccName, fileName = '未命名文件') => {
  // debugger
  if ((fileName == undefined || fileName == null || fileName == '' || fileName == '未命名文件') && ccName) {
    return ccName
  }

  if (fileName.indexOf('.') > -1) {
    return fileName
  }

  let ccNameArr = (ccName || '').split('.')

  let suffix = ccNameArr.length > 1 ? ccNameArr[ccNameArr.length - 1] : ''
  if (suffix) {
    suffix = '.' + suffix
  }
  return fileName + suffix
}

let getS = () => { // 生成随机key
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1) + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}
let scrollBar = (show = true) => {
  if (show) {
    console.log(show, 'scrollBar')
    let m = function(e){e.preventDefault();};
    document.body.style.overflow='hidden';
    document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑
  } else {
    let m =function(e){e.preventDefault();};
    document.body.style.overflow='';//出现滚动条
    document.removeEventListener("touchmove",m,{ passive:true });
  }
}

let listShow = (list, code, key = 'code') => { // 根据数组 返名称
  let text = ''
  let obj =  list.find(i => i[key] == code)
  if (obj && obj.name) {
    text = obj.name
  }
  return text
}

export default {
  findkeyIndex,
  changeTImeStamp,
  changeTimeDate,
  changePrice,
  validComForm,
  deepCopy,
  lockBg,
  lock,
  getHideMenu,
  getMenuListByCurrentUser,
  findParents,
  analysisUrl,
  GetUrlParam,
  getUrlSearch,
  getTypeData,
  loginFlaOrNot,
  totalAmount,
  catrAmount,
  openFile,
  getS,
  scrollBar,
  ssoLoginPath,
  downloadFile,
  listShow
}