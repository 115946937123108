const env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
const imgHostConfig = {
  prod: 'http://172.17.18.177:28081/',
  dev: 'http://172.17.18.177:28081/'
}
// 开发
// const urlHostConfig = {
//   prod: 'http://172.17.18.177:18081',
//   dev: 'http://172.17.18.177:18081'
// }
// //测试
const urlHostConfig = {
  prod: 'http://172.17.18.177:28081',
  dev: 'http://172.17.18.177:28081'
}

const parmarsData = {
  sign: '1'
}
export default {
  env,
  urlHost: urlHostConfig[env],
  imgHost: imgHostConfig[env], // 图片路径
  validConfig: true, // true  打开自定义验证 false 使用框架默认验证
  waterOff: true, // 是否打开水印,
  routerList: [ 'login' ]
}
