import axios from '@/libs/api.request'

export const getSystemInfos = data => {  // 获取系统信息
	return axios.request({
		url: '/api/jyq-base/admin/systeminfo/getSystemInfos',
		data: data,
		method: 'post'
	})
}

export const getMenuMap = data => {  // 官网菜单map
	return axios.request({
		url: '/api/jyq-base/admin/role/getMenuMap',
		data: data,
		method: 'post'
	})
}

export const customerServiceRecord = data => {  // 官网客服记录添加
	return axios.request({
		url: '/api/jyq-base/tenant/customer/customerServiceRecord',
		data: data,
		method: 'post'
	})
}

export const queryEnterpriseBusiness = data => {  // 查询企业信息
	return axios.request({
		url: '/api/api/queryEnterpriseBusiness',
		data: data,
		method: 'post'
	})
}

export const becomeAgent = data => {  // 成为代理商（首次成为代理商）
	return axios.request({
		url: '/api/jyq-base/tenant/agent/becomeAgent',
		data: data,
		method: 'post'
	})
}

export const goodsGoodsPage = data => {  // 商品分页
	return axios.request({
		url: '/api/jyq-base/admin/goods/goodsPage',
		data: data,
		method: 'post'
	})
}

export const goodsClassSubLevelByGoodsClass = data => {  // 商品分页
	return axios.request({
		url: '/api/jyq-base/admin/goods/goodsClassSubLevelByGoodsClass',
		data: data,
		method: 'post'
	})
}

export const industrySolutionDetails = data => { // 行业方案详情
	return axios.request({
		url: '/api/jyq-base/admin/goods/goodsDetails',
		data: data,
		method: 'post'
	})
}

export const goodsDetailsByParticulars = data => { // 行业方案商品详情
	return axios.request({
		url: '/api/jyq-base/admin/goods/goodsDetailsByParticulars',
		data: data,
		method: 'post'
	})
}

export const videoPage = data => { // 视频列表
	return axios.request({
		url: '/api/jyq-base/admin/goods/videoPage',
		data: data,
		method: 'post'
	})
}

export const applicablePlatformEnum = data => {  // 适用平台Enum
	return axios.request({
		url: '/api/jyq-base/admin/goods/applicablePlatformEnum',
		data: data,
		method: 'post'
	})
}

export const productApplicationTree = data => {  // 产品应用下的分组信息
	return axios.request({
		url: '/api/jyq-base/admin/goods/productApplicationTree',
		data: data,
		method: 'post'
	})
}

export const getGoodsList = data => {  // 根据分组信息查询
	return axios.request({
		url: '/api/jyq-base/admin/goods/getGoodsList',
		data: data,
		method: 'post'
	})
}


export const cartGoodsShopping = data => {  // 商品选购-立即结算
	return axios.request({
		url: '/api/jyq-base/tenant/cartGoods/shopping',
		data: data,
		method: 'post'
	})
}

export const getCurEffectiveCartAndOrder = data => {  // 获取当前用户有效的购物车和订单
	return axios.request({
		url: '/api/jyq-base/tenant/cartGoods/getCurEffectiveCartAndOrder',
		data: data,
		method: 'post'
	})
}

export const cartDeleteById = data => {  // 根据商品id删除购物车商品
	return axios.request({
		url: '/api/jyq-base/tenant/cartGoods/deleteGoodsById',
		data: data,
		method: 'post'
	})
}

// export const createOrderByCart = data => {  // 购物车去支付下单
// 	return axios.request({
// 		url: '/api/jyq-base/tenant/order/createOrderByCart',
// 		data: data,
// 		method: 'post'
// 	})
// }

export const createOrderByCart = (data) => {  // 购物车去支付---new
  	return axios.request({
  		url: `api/jyq-base/tenant/cart/createOrderByCart?payRemarks=${data.payRemarks}&moduleVerification=${data.moduleVerification}`,
  		data: data,
  		method: 'post'
  	})
  }

export const createOrderByOrder = data => {  // 订单去支付
	return axios.request({
		url: '/api/jyq-base/tenant/cart/createOrderByOrder',
		data: data,
		method: 'post'
	})
}

export const deleteGoodsById = data => {  // 根据商品id删除购物车商品
	return axios.request({
		url: '/api/jyq-base/tenant/cartGoods/deleteGoodsById',
		data: data,
		method: 'post'
	})
}

export const updateCartBuyDuration = data => {  // 修改购物车时长
	return axios.request({
		url: '/api/jyq-base/tenant/cart/updateCartBuyDuration',
		data: data,
		method: 'post'
	})
}

export const updateCartBuyNumber = data => {  // 修改购物车人数
	return axios.request({
		url: '/api/jyq-base/tenant/cart/updateCartBuyNumber',
		data: data,
		method: 'post'
	})
}

export const updateHardWareGoodsByNumber = data => {  // 修改数量
	return axios.request({
		url: '/api/jyq-base/tenant/cart/updateCartGoodsNumber',
		data: data,
		method: 'post'
	})
}


export const getCurUserEffectiveCartDependGoodsList = data => {  // 获取当前用户有效的购物车依赖商品
	return axios.request({
		url: '/api/jyq-base/tenant/cartGoods/getCurUserEffectiveCartDependGoodsList',
		data: data,
		method: 'post'
	})
}

export const getOffShelfGoodsByCurCart = data => {  // 获取当前用户有效的购物车 已下架商品---new
	return axios.request({
		url: '/api/jyq-base/tenant/cart/getOffShelfGoodsByCurCart',
		data: data,
		method: 'post'
	})
}