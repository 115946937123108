import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import comConfig from '@/common/config'
import mixCommon from '@/common/mixCommon.js'
import '@/common/resize.js'
import '@/styles/index.less'
import '@/assets/icons/iconfont.css'
import videojs from "video.js";
import "video.js/dist/video-js.css";

import importDirective from '@/directive'

import AOS from 'aos'
import 'aos/dist/aos.css'

import goTop from '@/views/components/goTop/goTop.vue' // 返回顶部

router.beforeEach((to, from, next) => {
  //  从from 跳转到 to
  if (to.matched[1] && to.matched[1].meta && to.matched[1].meta.title) {
    document.title =  to.matched[1].meta.title
  } else {
    document.title = '应用商店'
  }
  //  一定要写！！
  next()
})

document.addEventListener('click', function () {
  store.commit('changeShowTime', 3600)
})
setInterval(() => {
  if (!store.state.timeIng && sessionStorage.getItem('uInfo')) {
    let time = sessionStorage.getItem('timeIng') === true ? sessionStorage.getItem('showTime') : store.state.showTime
    time--
    store.commit('changeShowTime', time)
    if (time == 0) {
      mixCommon.lockBg()
    }
  }
}, 1000)

Vue.use(AOS);
AOS.init();
Vue.use(ViewUI);
Vue.config.productionTip = false
/**
 * @description 全局注册应用配置
 */
Vue.prototype.$video = videojs;
Vue.prototype.comConfig = comConfig
Vue.prototype.mixCommon = mixCommon
Vue.prototype.findkeyIndex = mixCommon.findkeyIndex
Vue.prototype.changeTImeStamp = mixCommon.changeTImeStamp
Vue.prototype.changeTimeDate = mixCommon.changeTimeDate
Vue.prototype.downloadFile = mixCommon.downloadFile
Vue.prototype.changePrice = mixCommon.changePrice
Vue.prototype.validComForm = mixCommon.validComForm
Vue.prototype.deepCopy = mixCommon.deepCopy
Vue.prototype.getUrlSearch = mixCommon.getUrlSearch
Vue.prototype.loginFlaOrNot = mixCommon.loginFlaOrNot
Vue.prototype.totalAmount = mixCommon.totalAmount
Vue.prototype.openFile = mixCommon.openFile


//自定义封装组件  start
import Form from '@/components/iview-com/components/form/index'
Vue.use(Form);
Vue.component('Form', Form)
import FormItem from '@/components/iview-com/components/form-item/index'
Vue.use(FormItem);
Vue.component('FormItem', FormItem)
import Tooltip from '@/components/iview-com/components/tooltip/index'
Vue.use(Tooltip);
Vue.component('Tooltip', Tooltip)
import Step from '@/components/iview-com/components/step/index'
Vue.use(Step);
Vue.component('Step', Step)
import Steps from '@/components/iview-com/components/steps/index'
Vue.use(Steps);
Vue.component('Steps', Steps)
//自定义封装组件  end

// 全局组件挂载
Vue.component('goTop', goTop)
/**
 * 注册指令
 */
importDirective(Vue)
Vue.directive('preventReClick', {
  inserted (el, binding) {
    // debugger
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        el.style.pointerEvents = 'none'
        el.style.opacity = 0.5
        setTimeout(() => {
          el.disabled = false
          el.style.pointerEvents = ''
          el.style.opacity = 1
        },binding.value || 2000)
      }
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
