import axios from 'axios'
import router from '@/router/index'
import { Message, Spin, Modal } from 'view-design'
import store from '@/store'
import DomMessage from './messageOnce'
import mixCommon from '@/common/mixCommon.js'
const messageOnce = new DomMessage()

class HttpRequest {
  constructor (baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {
      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      Spin.hide()
    }
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      if (sessionStorage.getItem('token')) {
        config.headers['accesstoken'] = sessionStorage.getItem('token')
      }
      let urlName = JSON.parse(JSON.stringify(config.url))
      // config.url = spiltUrl(config.url, 'xx_api') // 更改前缀

      let comP = isPamars(config)
      config.url = config.url + comP
      // 添加全局的loading...
      if (!config.data) {
        config.data = {}
      }
      // if (!(config.data instanceof Array)) {
      //   let frontParams = store.state.menuName.frontParams ? store.state.menuName.frontParams : {}
      //   // Object.assign(config.data, frontParams)
      // }
      // config.data = { ...JSON.parse(store.state.frontParams || '{}'), ...config.data }
      if (!Object.keys(this.queue).length && !config.data.hasOwnProperty('isLoading') && config.data.loadingOff) {
      // if (!Object.keys(this.queue).length && !config.data.hasOwnProperty('isLoading')) {
        Spin.show() // 不建议开启，因为界面不友好
        Spin.show({
          render: (h) => {
            return h('div', [
              h('Icon', {
                'class': 'demo-spin-icon-load',
                props: {
                  type: 'ios-loading',
                  size: 35
                }
              }),
              h('div', 'Loading')
            ])
          }
        })
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data, status, headers, config } = res
      if (headers && headers.accesstoken) {
        store.commit('set_token', headers.accesstoken)
      }

      // 存储枚举数据
      let strUrl = config.url
      if (strUrl.split('?')[0] == '/api/api/getEnumListByCls') {
        if (data.data && ((Array.isArray(data.data) && data.data.length > 0) || (!Array.isArray(data.data) && Object.keys(data.data) > 0))) {
          let emData = JSON.parse(sessionStorage.getItem('enumerationData')) || {}
          let parmarsObj = JSON.parse(config.data)
          emData[`${parmarsObj.enumClassName}`] = data.data
          store.commit('setEnumerationData', emData)
        }
      } else if (strUrl.split('?')[0] == '/api/api/getDictListByDicType') {
        if (data.data && ((Array.isArray(data.data) && data.data.length > 0) || (!Array.isArray(data.data) && Object.keys(data.data) > 0))) {
          let emData = JSON.parse(sessionStorage.getItem('dictypeData')) || {}
          let parmarsObj = JSON.parse(config.data)
          emData[`${parmarsObj.typeCode}`] = data.data
          store.commit('setDictypeData', emData)
        }
      }

      if (data && typeof data === 'object' && !(data instanceof Blob) && data.code != 0 && data.code != 50001 && data.code != 9898) {
        // Message.error(data.msg)
        messageOnce.error((data.msg || '').replace(/\n/g, '<br/>'))
      }
      if (data.code == 4) { // 认证失败  重新登录
        store.commit('del_uInfo')
        store.commit('del_token')
        const mask_bg = document.querySelector('#mask_bg')
        if (mask_bg) {
          store.commit('changeShowTime', 3600)
          store.commit('changeTimeIng', false)
          document.body.removeChild(mask_bg)
          store.commit('del_all')
        }
        setTimeout(() => {
          router.push({ name: 'home', query: {} })
        }, 800)
      }
      if (data.code === 18 && !this.showBox) {
        store.commit('changeShowTime', 0)
        const mask_bg = document.querySelector('#mask_bg')
        mask_bg || mixCommon.lockBg()
        mixCommon.lock()
      }
      return { data, status, headers }
    }, error => {
      if (error.request && error.request.responseType === 'blob') {
        console.log('请求错误')
      } else {
        // Message.error('请求错误')
        messageOnce.error(('请求错误' || '').replace(/\n/g, '<br/>'))
      }
      this.destroy(url)
      let errorInfo = error.response
      //   if (!errorInfo) {
      //     const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
      //     errorInfo = {
      //     //   statusText,
      //       status,
      //       request: { responseURL: config.url }
      //     }
      //   }
      //   addErrorLog(errorInfo)
      return Promise.reject(error)
    })
  }
  request (options) {
    // 对象实例

    const instance = axios.create({
      timeout: options.timeout ? options.timeout : 1000 * 60 * 2,
      withCredentials: options.withCredentials != false
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
let spiltUrl = (url, name) => {
  if (url) {
    let arr = url.split('/')
    let newUrl = ''
    if (arr[0] == 'api') {
      if (name) {
        arr[0] = name
      } else {
        arr.splice(0, 1)
      }
      newUrl = arr.join('/')
    }
    if (arr[1] == 'api') {
      if (name) {
        arr[1] = name
      } else {
        arr.splice(1, 1)
      }
      newUrl = arr.join('/')
    }
    return newUrl || url
  }
}
let isPamars = (params) => {
  let lengths = 0
  for (const key in params.data) {
    if (isObject(params.data[key])) { // 值不能为对象
      lengths = 2
    }
    lengths++
  }
  let parmarsData = params.data
  let comP = ''
  let keyIndex = 1
  if (lengths == 1) {
    if (params.url.substr(params.url.length - 1, 1) == '?') {
      for (let key in parmarsData) {
        comP += `&${key}=${parmarsData[key]}`
      }
    } else {
      for (let key in parmarsData) {
        if (keyIndex == 1) {
          comP += `?${key}=${parmarsData[key]}`
        } else {
          comP += `&${key}=${parmarsData[key]}`
        }
        keyIndex++
      }
    }
  }
  return comP
}
const isObject = (obj) => {
  if (obj && obj instanceof Object) {
    return true
  } else {
    return false
  }
}

export default HttpRequest
