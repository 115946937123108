<template>
    <div :class="wrapClasses" :style="styles">
        <!-- <div :class="[prefixCls + '-iconme']" v-if="stepNumber>1"><i  class="iconfont icon-youshuangjiantou"></i></div> -->
        <div :class="[prefixCls + '-tail']"><i></i></div>
        <div :class="[prefixCls + '-head']">

            <div :class="[prefixCls + '-head-inner']">
              <!-- <div :class="[prefixCls + '-iconme']" v-if="stepNumber>1"><i  class="iconfont icon-youshuangjiantou"></i></div> -->
              <!-- <i  :class="[prefixCls + '-iconme']" v-if="stepNumber>1" class="iconfont icon-youshuangjiantou"></i> -->
                <span v-if="!icon && currentStatus != 'finish' && currentStatus != 'error'">{{ stepNumber }}</span>
                <span v-else :class="iconClasses"></span>
            </div>
        </div>
        <div :class="[prefixCls + '-main']">
            <div :class="[prefixCls + '-title']">{{ title }}</div>
            <slot name="content">
                <div v-if="content" :class="[prefixCls + '-content']">{{ content }}</div>
            </slot>
        </div>
    </div>
</template>
<script>
import Emitter from '../../mixins/emitter'
import { oneOf } from '../../utils/assist'

const prefixCls = 'ivu-steps'
const iconPrefixCls = 'ivu-icon'

export default {
  name: 'Step',
  mixins: [ Emitter ],
  props: {
    status: {
      validator (value) {
        return oneOf(value, ['wait', 'process', 'finish', 'error'])
      }
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String
    },
    icon: {
      type: String
    }
  },
  data () {
    return {
      prefixCls: prefixCls,
      stepNumber: '',
      nextError: false,
      total: 1,
      currentStatus: ''
    }
  },
  computed: {
    wrapClasses () {
      return [
        `${prefixCls}-item`,
        `${prefixCls}-status-${this.currentStatus}`,
        {
          [`${prefixCls}-custom`]: !!this.icon,
          [`${prefixCls}-next-error`]: this.nextError
        }
      ]
    },
    iconClasses () {
      let icon = ''

      if (this.icon) {
        icon = this.icon
      } else {
        if (this.currentStatus == 'finish') {
          icon = 'ios-checkmark'
        } else if (this.currentStatus == 'error') {
          icon = 'ios-close'
        }
      }

      return [
        `${prefixCls}-icon`,
        `${iconPrefixCls}`,
        {
          [`${iconPrefixCls}-${icon}`]: icon != ''
        }
      ]
    },
    styles () {
      return {
        width: `${1 / this.total * 100}%`
      }
    }
  },
  watch: {
    status (val) {
      this.currentStatus = val
      if (this.currentStatus == 'error') {
        this.$parent.setNextError()
      }
    }
  },
  created () {
    this.currentStatus = this.status
  },
  mounted () {
    this.dispatch('Steps', 'append')
  },
  beforeDestroy () {
    this.dispatch('Steps', 'remove')
  }
}
</script>
<style scoped lang='less'>
.ivu-steps-iconme{
  color: #e8eaec;
  margin: 0 1rem;
  font-size: 1.4rem;
  i{
    font-size: 1.4rem;
  }
}
.ivu-steps-status-process{
  .ivu-steps-iconme{
    color: #2d8cf0;
  }
}
</style>
