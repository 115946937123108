import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import mixCommon from '@/common/mixCommon'
import store from '@/store'
import MenuMethods from '@/libs/menu.js'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {

  window.nowUrlName = to.name
  let valiToken = sessionStorage.getItem('token')
  if (!(to.meta && to.meta.hideInMenu) && !valiToken && to.name == 'payment-center') {
    console.log('数据', to, valiToken)
    Message.error('未登录!')
    // next({ name: 'login' })
  } else {
    next()
  }
})
// const findQxItem = (treeData, val) => {
//   let obj = null
//   treeData.map((item) => {
//     if (obj) return
//     if (item.code == val) {
//       obj = item.children[0]
//     } else if (item.children && item.children.length > 0) {
//       obj = findQxItem(item.children, val)
//     }
//   })
//   return obj
// }
// function formattingData(list=[], name) {
//   for (let i in list) {
//       let item = list[i];
//       if (item.frontPath === name) return [item];
//       if (item.children && item.children.length > 0) {
//           let node = formattingData(item.children, name)
//           if (node !== undefined) return node.concat(item)
//       }
//   }
// }
// router.beforeEach((to, from, next) => {
//   let leftMenu = JSON.parse(sessionStorage.getItem('leftMenu')) || store.state.leftMenu

//   //路由定位
//   if(leftMenu && leftMenu.length>0 ) {
//     let menuName = formattingData(leftMenu, to.name)
//     store.commit('set_menuName', {
//       activeNenu: menuName,
//       frontParams: menuName&&menuName.length>0&&menuName[0].frontParams? menuName[0].frontParams : {}
//     })
//   }

//   window.nowUrlName = to.name
//   let valiToken = sessionStorage.getItem('token')
//   if (!(to.meta && to.meta.hideInMenu) && !valiToken && to.name !== 'login') {
//     console.log('数据22', to, valiToken)
//     // Message.error('未登录!')
//     next({ name: 'login' })
//     store.commit('del_activeMenuList')
//   } else {
//     if (!(to.meta && to.meta.hideInMenu)) {
//       if (to.query) {
//         to.query.qts = new Date().getTime()
//       } else {
//         to.query = { qts: new Date().getTime() }
//       }
//     }
//     if (!MenuMethods.checkCodeShow(to.meta.code) && to.meta.code) {
//       let leftMenuArr = sessionStorage.getItem('leftMenu') ? JSON.parse(sessionStorage.getItem('leftMenu')) : []
//       let dataoBj = mixCommon.findParents(routes, to.meta.code)
//       let dataItem = dataoBj && dataoBj.length > 0 ? dataoBj[0] : null
//       let qxItem = findQxItem(leftMenuArr, dataItem.meta.code)
//       console.log('有权限的', leftMenuArr)
//       console.log('有权限的', dataoBj)
//       console.log('有权限的', dataItem)
//       console.log('有权限的', qxItem)
//       if (!dataItem && !qxItem) {
//         console.log('无权限', dataItem, qxItem)
//         Message.error('该菜单暂无权限')
//         store.commit('del_activeMenuList')
//         setTimeout(() => {
//           mixCommon.goPowerMenu()
//         }, 500)
//       } else if (qxItem) {
//         next({ name: qxItem.frontPath })
//       } else if (dataItem) {
//         next({ name: dataItem.name })
//       }
//       return
//     }

//     next()
//   }
// })
export default router