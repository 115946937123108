<template>
  <div class="go-top">
    <div class="ul">
      <div class="li" @click="experienceClick(1)">
        <img class="li-img" src="@/assets/images/go-top/li-img1.png" alt="">
        <span>免费试用</span>
      </div>
      <div class="li" @click="experienceClick(2)">
        <img class="li-img" src="@/assets/images/go-top/li-img2.png" alt="">
        <span>预约演示</span>
      </div>
      <Poptip class="li" placement="right">
        <div class="li">
          <img class="li-img" src="@/assets/images/go-top/li-img3.png" alt="">
          <span>在线咨询</span>
        </div>
        <div class="qr-img" slot="content">
          <img class="img" src="@/assets/images/go-top/erwm-jyqkfu.png" alt="">
          <span>扫一扫专属客服为您服务</span>
        </div>
      </Poptip>
    </div>
    <div class="li-top" @click="topClick" v-if="isShow">
      <i class="iconfont icon-pullup01"></i>
      <span>返回顶部</span>
    </div>
    <MakeAppointment :modalType="modalType" @modalOff="modalOff" :breadth="670"></MakeAppointment>
  </div>
</template>
<script>
export default {
  name: 'goTop',
  components: {
    MakeAppointment: resolve => (require(['@/views/components/confirm-modal/make-appointment.vue'], resolve)), // 立即预约
  },
  props: {
    modalMake: {
      type: [Boolean],
      default: false
    },
  },
  watch: {
    modalMake (val) {
      if (val) {
        this.experienceClick(2)
        this.$emit('modalClick', false)
      }
    }
  },
  data () {
    return {
      isShow: false,
      scrollTop: 0,
      modalType: false
    }
  },
  mounted () {
    window.addEventListener("scroll", this.getScrollTop);
  },
  methods: {
    modalOff (val) {
      if (!val) {
        this.modalType = false
      }
    },
    experienceClick(val) {
      if (val == 1) {
        // let url = `${process.env.VUE_APP_SSO_LOGIN_PATH}/login`
        // window.open(url, '_blank');
        this.mixCommon.ssoLoginPath('jyqUrl', 'login')
      } else if (val == 2) {
        this.modalType = true
      }
    },
    topClick() {
      this.$store.commit('set_topScroll', true)
    },
    // 监听滚动条
    getScrollTop(e) {
      let that = this; // 防止this指向问题
      this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
      if (that.scrollTop > 500) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScrollTop);
  }
}
</script>
<style lang="less" scoped>
.go-top {
    position: fixed;
    top: 25rem;
    right: 13.8rem;
    z-index: 99;
    .ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4.1rem 1.6rem 4.5rem 1.6rem;
      background: linear-gradient(0deg, #006EFF, #3A8EFF);
      box-shadow: 0rem 0rem 1.8rem 0rem rgba(156,156,156,0.42);
      border-radius: 4.4rem;
      .li {
        cursor: pointer;
        width: 5.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .li-img {
          width: 3rem;
          height: 3rem;
        }
        span {
          margin-top: 1.3rem;
          font-size: 1.4rem;
          font-weight: 500;
          color: #FFFEFE;
          white-space: nowrap;
        }
      }
      .li:nth-child(2) {
        border-top: .1rem solid rgba(255, 255, 255, 0.42);
        border-bottom: .1rem solid rgba(255, 255, 255, 0.42);
        padding: 3.6rem 0rem;
        margin: 3.1rem 0rem;
        .li-img {
          width: 2.9rem;
          height: 2.9rem;
        }
      }
      .li:nth-child(3) {
        .li-img {
          width: 3.2rem;
          height: 2.6rem;
        }
        .qr-img {
          width: 100%;
          min-width: 120px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .img {
            width: 10.5rem;
            min-width: 100px;
            height: 10.5rem;
            min-height: 100px;
          }
          span {
            font-size: 1.4rem;
            font-weight: 500;
            color: #000;
          }

        }
      }
    }
    .li-top {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 8.8rem;
      height: 8.8rem;
      background: #FFFFFF;
      box-shadow: 0rem 0rem 1rem 0rem rgba(156,156,156,0.32);
      border-radius: 4.4rem;
      margin-top: 1.9rem;
      i {
        font-size: 2.8rem;
        font-weight: 500;
        color: #888888;
        line-height: 1;
      }
      span {
        font-size: 1.4rem;
        font-weight: 500;
        color: #888888;
      }
    }
  }
</style>