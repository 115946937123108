// 文档中心
import axios1 from 'axios'
import axios from '@/libs/api.request'
import HttpRequest from '@/libs/axios'

// 获取左侧组织架构树
export const queryDocTree = () => {
	return axios.request({
		url: '/api/doc/queryDocTree',
		method: 'post'
	})
}

// 文档树查询-逐层加载
export const getDocTree = (data) => {
	return axios.request({
		url: '/api/doc/getDocTree',
		method: 'post',
		data
	})
}

// 获取档案文件列表
export const queryDocFilesPage = (data) => {
	return axios.request({
		url: '/api/doc/queryDocFilesPage',
		method: 'post',
		data
	})
}

// 文件下载-oss
export const ossDownload = (data, params) => {
	if (data.url) {
		return axios1.request({
			url: data.url,
			method: 'get',
			responseType: "blob",
			timeout: 3600000,
		})
	} else {
		return axios.request({
			data,
			url: `/api/api/ossDownload`,
			method: 'post',
			responseType: "blob",
			responseEncoding: 'binary',
			timeout: '3600000',
			...params
		})
	}
}

// 文件下载-doc
export const download = (data, params) => {
	return axios.request({
		data: data,
		url: `/api/api/download`,
		method: 'post',
		...params
	})
}

// 文件预览-oss
export const ossPreviewUrl = (data) => {
  return axios.request({
    data: data,
    url: `/api/api/ossPreviewUrl?path=${data.path}`,
    method: 'post'
  })
}

// 文件预览-doc
export const previewUrl = (data) => {
	return axios.request({
		data: data,
		url: `/api/api/previewUrl`,
		method: 'post'
	})
}

// 获取档案文档编号查列表
export const queryDocFilesByDocumentNo = (data) => {
	return axios.request({
		url: '/api/doc/queryDocFilesByDocumentNo',
		method: 'post',
		data
	})
}
// 文件管理新增文件
export const addDocFilesOnTree = (data) => {
	return axios.request({
		data: data,
		url: `/api/doc/addDocFilesOnTree`,
		method: 'post'
	})
}
