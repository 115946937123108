import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showTime: 3600, // 过长没操作
    uInfo: '',
    token: '',
    tenantInfo: '',
    menuList: [], // 菜单
    atPresentMenu: {}, // 当前菜单
    atSecondMenu: {}, // 当前菜单二级菜单
    leftMenu: [],
    topScroll: false, // 返回顶部
    relyShow: false,
    moduleLoad: false, // 模块加入购物车
    relyList: [],
    cartCount: '', // 购物车数量
    typeMethods: '',
  },
  getters: {
  },
  mutations: {
    setTypeMethods (state, data) {
      state.typeMethods = data
    },
    set_topScroll (state, data) { // 返回顶部
      state.topScroll = JSON.stringify(data)
      sessionStorage.setItem('topScroll', data)
    },
    set_moduleLoad (state, data) { // 模块加入购物车
      state.moduleLoad = JSON.stringify(data)
      sessionStorage.setItem('moduleLoad', data)
    },
    set_relyShow (state, data) { // 弹窗依赖
      state.relyShow = JSON.stringify(data)
      sessionStorage.setItem('relyShow', data)
    },
    set_relyList (state, data) { // 弹窗依赖
      state.relyList = JSON.stringify(data)
      sessionStorage.setItem('relyList', data)
    },
    del_relyShow (state) {
      state.relyShow = false
      state.relyList = []
      sessionStorage.removeItem('relyList')
    },
    set_cartCount (state, data) { // 购物车数量
      state.cartCount = JSON.stringify(data)
      sessionStorage.setItem('cartCount', data)
    },
    changeShowTime (state, time) {
      state.showTime = time
      sessionStorage.setItem('showTime', time)
    },
    set_atPresentMenu (state, data) {
      state.atPresentMenu = data
      sessionStorage.setItem('atPresentMenu', data)
    },
    set_atSecondMenu (state, data) {
      state.atPresentMenu = data
      sessionStorage.setItem('atSecondMenu', data)
    },
    set_token (state, data) {
      state.token = data
      sessionStorage.setItem('token', data)
    },
    setEnumerationData (state, data) { // 设置枚举库
      state.enumerationData = data
      sessionStorage.setItem('enumerationData', JSON.stringify(data))
    },
    setDictypeData (state, data) { // 设置字典库
      state.dictypeData = data
      sessionStorage.setItem('dictypeData', JSON.stringify(data))
    },
    del_token (state) {
      state.token = ''
      sessionStorage.removeItem('token')
    },
    changeOption (state) {
      state.optionChange++
    },
    set_menuList (state, info) { // 登录成功获取用户菜单list
      state.menuList = info
      sessionStorage.setItem('menuList', JSON.stringify(info))
    },
    set_uInfo (state, info) {
      state.uInfo = info
      sessionStorage.setItem('uInfo', JSON.stringify(info))
    },
    set_tenantInfo (state, data) {
      state.tenantInfo = data
      sessionStorage.setItem('tenantInfo', JSON.stringify(data))
    },
    del_tenantInfo (state, data) {
      state.tenantInfo = ''
      sessionStorage.removeItem('tenantInfo')
    },
    del_uInfo (state) {
      state.uInfo = ''
      state.cartCount = ''
      sessionStorage.removeItem('uInfo')

      state.enumerationData = {}
      sessionStorage.removeItem('enumerationData')

      state.dictypeData = {}
      sessionStorage.removeItem('dictypeData')
    },
    del_all (state, data) {
      state.uInfo = ''
      state.token = ''
      state.menuList = ''
      state.tenantInfo = ''
      sessionStorage.clear()
    },
    set_leftMenu (state, data) {
      state.leftMenu = data
      let newData = JSON.stringify(JSON.parse(JSON.stringify(data)))
      let obj = { arr: newData }
      let objNew = Object.assign({}, obj)
      sessionStorage.setItem('leftMenu', JSON.stringify(JSON.parse(JSON.stringify(data))))
    }
  },
  actions: {
  },
  modules: {
  }
})
