
import { Message } from 'view-design'
import router from '../router'
// 私有属性，只在当前文件可用
const showMessage = Symbol('showMessage')
export default class DomMessage {
  duration = 5

  success (options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    this[showMessage]('error', options, single)
  }

  [showMessage] (type, options, single) {
    if (single) {
      // 关键代码，判断当前页是否有ivu-message-notice标签，如果没有则执行弹窗操作

      let strArr = options.split('【')
      let optionsStr = ''
      if (strArr[0] == '') {
        strArr.shift()
        for (var value of strArr) {
          optionsStr += value.split('】')[1]
        }
      } else {
        optionsStr = this.getV(options)
      }
      if (!document.querySelector('.ivu-message-notice')) {
        if (optionsStr) {
          router.app.$Message[type]({
            // content: `<br/>${optionsStr}`,
            content: `${optionsStr}`,
            duration: this.duration
          })
        } else {
          router.app.$Message[type]({
            content: options,
            duration: this.duration
          })
        }
        setTimeout(() => {
        }, this.duration * 1000)
        console.log('报错信息', type, options, single, optionsStr)
      }
    } else {
      Message[type](options)
    }
  }
  getV (val) {
    let arr = val.split('')
    let res = ''
    let nums = []
    arr = arr.map((item, index) => {
      if (item === '【') {
        item = ''
      }
      if (item === '】') {
        nums.push(index)
        item = ','
      }
      return item
    })
    let num = nums.length - 1
    if (arr[arr.length - 1] == ',') {
      arr.pop()
    }
    arr.splice(num, 0)
    res = arr.join('')
    return res
  }
}

// import { Message } from 'view-design'
// // 私有属性，只在当前文件可用
// const showMessage = Symbol('showMessage')
// export default class domMessage {
//   success (options, single = true) {
//     this[showMessage]('success', options, single)
//   }
//   warning (options, single = true) {
//     this[showMessage]('warning', options, single)
//   }
//   info (options, single = true) {
//     this[showMessage]('info', options, single)
//   }
//   error (options, single = true) {
//     this[showMessage]('error', options, single)
//   }
//   [showMessage] (type, options, single) {
//     if (single) {
//       // 关键代码，判断当前页是否有ivu-message-notice标签，如果没有则执行弹窗操作
//       if (document.getElementsByClassName('ivu-message-notice').length === 0) {
//         Message[type](options)
//       }
//     } else {
//       Message[type](options)
//     }
//   }
// }
