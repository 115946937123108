<template>
    <form  :class="classes" :autocomplete="autocomplete"><slot ></slot></form>
</template>
<script>
import { oneOf } from '../../utils/assist'

const prefixCls = 'ivu-form'

export default {

  name: 'iForm',
  props: {
    idName: [String, Number],
    model: {
      type: Object
    },
    rules: {
      type: Object
    },
    labelWidth: {
      type: Number
    },
    labelPosition: {
      validator (value) {
        return oneOf(value, ['left', 'right', 'top'])
      },
      default: 'right'
    },
    inline: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      validator (value) {
        return oneOf(value, ['on', 'off'])
      },
      default: 'on'
    }
  },
  provide () {
    return { form: this }
  },
  data () {
    return {
      fields: []
    }
  },
  computed: {
    classes () {
      return [
        `${prefixCls}`,
        `${prefixCls}-label-${this.labelPosition}`,
        {
          [`${prefixCls}-inline`]: this.inline
        }
      ]
    }
  },
  methods: {
    ergodicData (dom) {
      let xDOM = $('#' + dom + ' input')
      console.log('xDOM', xDOM)
      let arr = []
      for (var i = 0; i < xDOM.length; i++) {
        // console.log(xDOM.eq(i)[0].type, xDOM.eq(i)[0].localName, xDOM.eq(i)[0].name, xDOM.eq(i)[0].value)
        if (xDOM.eq(i)[0].type == 'text' && xDOM.eq(i)[0].name) {
          let obj = { key: xDOM.eq(i)[0].name, value: xDOM.eq(i)[0].value }
          arr.push(obj)
        }
      }
      return arr
    },
    async setName () {
      console.log('222', this.ergodicData(this.idName))
      let nameArr = await this.ergodicData(this.idName)
      nameArr.map((item, index) => {
        this.mixCommon.comSetData(item.key, item.value)
      })
    },
    resetFields () {
      this.fields.forEach(field => {
        field.resetField()
      })
    },
    validate (callback) {
      return new Promise(resolve => {
        let valid = true
        let count = 0
        this.fields.forEach(field => {
          field.validate('', errors => {
            if (errors) {
              valid = false
            }
            if (++count === this.fields.length) {
              // all finish
              resolve(valid)
              if (typeof callback === 'function') {
                callback(valid)
              }
            }
          })
        })
      })
    },
    validateField (prop, cb) {
      const field = this.fields.filter(field => field.prop === prop)[0]
      if (!field) { throw new Error('[iView warn]: must call validateField with valid prop string!') }

      field.validate('', cb)
    }
  },
  watch: {
    rules () {
      this.validate()
    }
  },
  created () {
    this.$on('on-form-item-add', (field) => {
      if (field) this.fields.push(field)
      return false
    })
    this.$on('on-form-item-remove', (field) => {
      if (field.prop) this.fields.splice(this.fields.indexOf(field), 1)
      return false
    })
  }
}
</script>
