import axios from '@/libs/api.request'
import axios1 from 'axios'
// 生成签名
export const getSignature = (data) => {
	return axios.request({
		data: data,
		url: `/api/api/doSignature`,
		method: 'post'
	})
}
export const saveErrorLogger = info => {
	return axios.request({
		url: '/api/sysOplog/saveErrorLogger',
		data: info,
		method: 'post'
	})
}

// 枚举
export const getEnumListByCls = (data) => {
	let kuData = JSON.parse(sessionStorage.getItem('enumerationData')) || {}
	if (kuData.hasOwnProperty(`${data.enumClassName}`)) {
		// console.log(data.enumClassName,'存过值了',kuData[`${data.enumClassName}`])
		return new Promise((resolve, reject) => {
			let list = kuData[`${data.enumClassName}`]
			resolve({
				data: {
					code: 0,
					data: list,
					mag: 'ok'
				}
			})
		})
	} else {
		// console.log('请求了接口数据')
		return axios.request({
			data: data,
			url: `/api/api/getEnumListByCls`,
			method: 'post',
			headers: {
				noEncryption: true
			}
		})
	}
}

// 获取字典
export const getDictListByDicType = (data = {}) => {
	let otherData = {
		flag: true
	}
	Object.assign(data, otherData)
	let kuData = JSON.parse(sessionStorage.getItem('dictypeData')) || {}

	if (kuData.hasOwnProperty(`${data.typeCode}`)) {
		return new Promise((resolve, reject) => {
			let list = kuData[`${data.typeCode}`]
			resolve({
				data: {
					code: 0,
					data: list,
					mag: 'ok'
				}
			})
		})
	} else {
		return axios.request({
			data: data,
			url: `/api/api/getDictListByDicType?typeCode=${data.typeCode}`,
			method: 'post',
			headers: {
				noEncryption: true
			}
		})
	}
}


// 获取租户信息ById
export const queryCurrentTenant = (data) => {
	return axios.request({
		data: data,
		url: `/api/tenant/queryCurrentTenant`,
		method: 'post'
	})
}

// 获取登录用户
export const getCurrentUser = (data) => {
  return axios.request({
    data: data,
    url: '/api/admin/user/getCurrentHrUserInfo',
    method: 'post'
  })
}