import { getMenuTreetByUser } from '@/api/common'
import store from '@/store'
import { Message, Spin } from 'view-design'
import router from '@/router/index' // true  打开管理 false  关闭过滤
import { getCodeList } from './code_group.js'
import mixCommon from '@/common/mixCommon.js'
let routers = window.allRouters
let offTest = true
let test = false // false  打开权限  true  关闭权限
class MenuMethods {
  getAllMenu = () => {
    return Object.assign([], routers)
  }

  getMenu = () => {
    return new Promise(async (resolve) => {
      getMenuTreetByUser().then(async res => {
        const { code, data, msg } = res.data
        console.log('eeee', data)
        if (code == 0) {
          // 修改字段
          data.menuTree = data.menuTree.map((v) => {
            return {
              ...v,
              name: v.title,
              realChildren: v.realChildren.map((_v) => {
                return {
                  ..._v,
                  name: v.title
                }
              })
            }
          })

          store.commit('set_menuList', data.menuMap ? data.menuMap : {})

          /**
             * @description 初始化设置面包屑导航和标签导航
             */
          let allMenuList = routers // 本地路由
          console.log(allMenuList)

          let userMenuList = JSON.parse(sessionStorage.getItem('menuList')) // 后端所有路由文件

          let menuAllList = []
          getCodeList().forEach(code => {
            initAllCodeGroupsMenu(code, userMenuList, menuAllList)
          })
          allMenuList.forEach(menu => { // 将 本地路由 和 后端路由 进行循环过滤
            initAllMenu(menu, userMenuList, menuAllList)
          })
          console.log('好地方',menuAllList)
          if (menuAllList.length <= 0) {
            Message.error('登录失败,该用户暂无菜单权限')
            router.replace({ path: '/login' })
            sessionStorage.clear()
            Spin.hide()
            return
          }

          // console.log('过滤之后得路由', menuAllList)// menuMap

          let currentMenuList = []
          if (data.menuTree && offTest) {
            data.menuTree.forEach(menu => {
              let currentMenu = initMenuTree(menu, menuAllList)
              if (currentMenu) currentMenuList.push(currentMenu)
            })
          }
          // console.log(currentMenuList)
          currentMenuList = await initRouterOther(currentMenuList, allMenuList)
          sortMenu(currentMenuList)
          // console.log('过滤之后得路由2', currentMenuList) // menuTree
          // let resData = await MenuNewMethods.getMenu1(currentMenuList)
          // console.log('防护',currentMenuList)

          if (test) {
            // console.log('routers', routers)
            return resolve(routers)
          }
          store.commit('set_currentMenuList', currentMenuList)
          return resolve(currentMenuList)
        }
      })
    })
    function initRouterOther (currentMenuList, allMenuList) {
      return new Promise(async (resolve) => {
        let otherRouter = mixCommon.getHideMenu()
        // console.log('otherRouter', otherRouter,allMenuList)
        await cctt(allMenuList)
        function cctt (list) {
          for (let routerName of otherRouter) {
            let otherRouterData = list.filter(router => {
              return router.name == routerName
            })

            let currentMenuRouter = currentMenuList.filter(router => {
              return router.name == routerName
            })
            if ((otherRouterData && otherRouterData.length > 0) && !(currentMenuRouter && currentMenuRouter.length > 0)) {
              if (otherRouterData && otherRouterData.length) {
                otherRouterData.forEach(router => {
                  router['type'] = 'other'
                })
                currentMenuList = currentMenuList.concat(otherRouterData)
              }
            }
          }
        }
        allMenuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            cctt(item.children)
          }
        })

        return resolve(currentMenuList)
      })
    }

    function initAllMenu (menu, userMenuList, menuAllList, code) {
      let tmpUserMenu = userMenuList[menu.meta.code]
      if (tmpUserMenu) {
        menuAllList.push(tmpUserMenu)
      }
      if (menu.children && menu.children.length > 0) {
        menu.children.forEach(menuc => {
          initAllMenu(menuc, userMenuList, menuAllList)
        })
      }

      let keys = Object.keys(userMenuList || {}).filter(key => menu.meta.code && menu.meta.code != code && key.indexOf(menu.meta.code + '_') > -1)
      for (let code of keys) {
        initAllMenu({ ...menu, meta: { ...menu.meta, code } }, userMenuList, menuAllList, menu.meta.code)
      }
    }

    function initAllCodeGroupsMenu (code, userMenuList, menuAllList) {
      let tmpUserMenu = userMenuList[code]
      if (tmpUserMenu) {
        menuAllList.push(tmpUserMenu)
      }
    }

    function initMenuTree (menu, allMenuList) {
      let result = {}
      let tmpUserMenu = allMenuList.filter(mn => mn.code === menu.code)

      if (tmpUserMenu && tmpUserMenu.length > 0) {
        Object.assign(result, tmpUserMenu[0])
        if (menu.children && menu.children.length > 0) {
          let children = []
          menu.children.forEach(menuc => {
            let menuC = initMenuTree(menuc, allMenuList)
            if (menuC) {
              children.push(menuC)
            }
          })
          result.children = children
          result.realChildren = children
        }
        return result
      }
    }

    function sortMenu (menuList) {
      menuList.sort((a, b) => {
        return a.sort - b.sort
      })
      menuList.forEach(menu => {
        if (menu.children && menu.children.length > 0) {
          sortMenu(menu.children)
        }
      })
    }
  }

  checkCodeShow = (code, sort) => {
    if (test) {
      return true
    }

    if (!code) {
      return false
    }

    let userMenuList = JSON.parse(sessionStorage.getItem('menuList'))

    if (!userMenuList) {
      return true
    }

    if (sort) {
      if (userMenuList[code]) {
        return userMenuList[code]
      } else {
        return false
      }
    } else {
      if (userMenuList[code]) {
        return true
      } else {
        return false
      }
    }
  }
}

export default new MenuMethods()
