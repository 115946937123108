import store from '@/store/index'
// JavaScript Document
(function px2rem (doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth
      let fontVal = 6
      if (!clientWidth) return
      let fontSize = fontVal
      if (clientWidth < 1100) {
        fontSize = 10 * (1100 / 1920)
      } else {
        fontSize = 10 * (clientWidth / 1920)
      }
      if (fontSize < fontVal) {
        docEl.style.fontSize = `${fontVal}px`
      } else {
        docEl.style.fontSize = `${fontSize || fontVal}px`
      }
      /*
      * 100 -> html,body {  font-size:100px; }
      * 750 -> 此处以 iPhone6 两倍设计稿 宽度750px 布局页面
      * 根据具体情况改变这两个数值
      */
      store.commit('changeOption')
    }
  if (!doc.addEventListener) return
  // 窗口大小发生变化，初始化
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
  // 防止在html未加载完毕时执行，保证获取正确的页宽
  setTimeout(function () {
    px2rem(doc, win)
  }, 200)
})(document, window)
